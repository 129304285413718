import Typography from '@common_typography';
import MagezonLink from '@core_modules/cms/components/cms-renderer/magezon/MagezonLink';
import cx from 'classnames';

const MagezonHeading = (props) => {
    const {
        xs_hide, sm_hide, md_hide, lg_hide, heading_type, text, link, font_weight, font_size, color, align,
    } = props;

    const style = {};
    if (color && color !== '') style.color = color;
    if (font_weight && font_weight !== '') style.fontWeight = font_weight;
    if (font_size && font_size !== '') style.fontSize = `${typeof font_size === 'number' ? `${font_size}px` : `${font_size.replace('px', '')}px`}`;
    if (align && align !== '') style.textAlign = align;

    return (
        <div
            className={cx('magezon-heading', {
                'max-sm:hidden': xs_hide,
                'max-md:hidden': sm_hide,
                'max-lg:hidden': md_hide,
                'max-xl:hidden': lg_hide,
            })}
        >
            {link && link !== '' ? (
                <MagezonLink link={link}>
                    <Typography variant={heading_type || 'h2'} style={style}>
                        {text || ''}
                    </Typography>
                </MagezonLink>
            ) : (
                <Typography variant={heading_type || 'h2'} style={style}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: text || '' }} />
                </Typography>
            )}
            <style jsx global>
                {`
                    .magezon-heading {
                        width: 100%;
                    }

                    .home-label-yellow,
                    .home-label-red {
                        border-bottom: 1px solid #f5f5f5;
                    }
                    
                    .home-label-red > :first-child,
                    .home-label-yellow > :first-child {
                        padding-bottom: 0 !important;
                    }
                    .home-label-yellow h2,
                    .home-label-red h2 {
                        position: relative;
                        font-size: 20px;
                        font-weight: 700;
                        display: inline-block;
                        width: auto !important;
                        margin: 0;
                        padding-bottom: 7px;
                    }
                    .home-label-yellow h2:before,
                    .home-label-red h2:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -1px;
                        width: 100%;
                    }
                    .home-label-yellow h2:before {
                        border-bottom: 4px solid #ffe604;
                    }
                    .home-label-red h2:before {
                        border-bottom: 4px solid #ff0000;
                    }
                `}
            </style>
        </div>
    );
};

export default MagezonHeading;
